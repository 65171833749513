import { createContext, useContext, ReactNode } from "react";

import { API } from "../api/api.types";

interface ApiProviderProps {
  api: API;
  children: ReactNode;
}

const Context = createContext<API | null>(null);

const useApi = (): API => {
  const context = useContext(Context);
  if (!context) throw new Error("Api provider not found");
  return context;
};

const ApiProvider = ({ api, children }: ApiProviderProps): JSX.Element => {
  return <Context.Provider value={api}>{children}</Context.Provider>;
};

export { ApiProvider, useApi };
