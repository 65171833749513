import {
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  styled,
} from "@grupoboticario/flora-react";

const StyledModalContent = styled(ModalContent, {
  "&&": {
    "@desktop": {
      minWidth: "560px",
      maxWidth: "560px",
      borderRadius: "$medium",
    },
  },
});

const StyledModalHeader = styled(ModalHeader, {
  "&&": {
    padding: "$5 $10 $5 $5",

    "@desktop": {
      padding: "$8 $8 $4",
    },
  },
});

const StyledModalCloseButton = styled(ModalCloseButton, {
  top: "$6",
  right: "$4",
  height: "$6",
  width: "$6",
  borderRadius: 0,
  padding: "0 !important",

  "@desktop": {
    top: "38px",
    right: "$8",
  },

  svg: {
    pointerEvents: "none",
  },
});

const StyledModalBody = styled(ModalBody, {
  "@desktop": {
    padding: "0 $8 $4",
  },
});

const StyledModalFooter = styled(ModalFooter, {
  flexDirection: "column",

  "@desktop": {
    padding: "$0 $8 $4",
  },
});

const Title = styled("h2", {
  fontSize: "$subtitleMobile",
  fontWeight: "$regular",
  lineHeight: "$short",
  marginBottom: 0,

  "@desktop": {
    fontSize: "$subtitleDesk",
  },
});

export {
  StyledModalContent,
  StyledModalHeader,
  StyledModalCloseButton,
  StyledModalBody,
  StyledModalFooter,
  Title,
};
