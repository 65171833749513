import { Fragment } from "react";

import { CSS } from "@grupoboticario/flora-react";

import {
  StyledModalBody,
  StyledModalHeader,
  Title,
} from "../modal-survey.styles";

import { Button, Container } from "./rating.styles";
import { SurveyQuestionProps } from "../modal-survey.types";

interface RatingProps {
  onRating?: (id: string) => void;
  surveyQuestions: SurveyQuestionProps[];
}

const Rating = ({ onRating, surveyQuestions }: RatingProps): JSX.Element => {
  const renderButtonList = (questions) => {
    return questions?.map(({ id, questionOption, label, color }) => {
      const spanStyle = {
        color: `#${color}`,
      };

      const buttonStyle: CSS = {
        "&:hover": {
          "span:first-of-type::before": {
            borderColor: `#${color}`,
          },
        },
      };

      return (
        <Button key={id} onClick={() => onRating(id)} css={buttonStyle}>
          <span style={spanStyle}>{questionOption}</span>
          <span>{label}</span>
        </Button>
      );
    });
  };

  const renderQuestion = surveyQuestions?.map((item) => {
    return (
      <Fragment key={item.id}>
        <StyledModalHeader>
          <Title>{item.question.question}</Title>
        </StyledModalHeader>
        <StyledModalBody>
          <Container>
            {renderButtonList(item.question.questionOptions)}
          </Container>
        </StyledModalBody>
      </Fragment>
    );
  });

  return <Fragment>{renderQuestion}</Fragment>;
};

export { Rating, RatingProps };
