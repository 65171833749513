import { Flex, styled } from "@grupoboticario/flora-react";

const Container = styled(Flex, {
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "$2",
  alignItems: "flex-start",

  "@desktop": {
    gap: "$4",
  },
});

const Button = styled("button", {
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "$2",
  maxWidth: "86px",
  borderWidth: 0,
  backgroundColor: "transparent",
  padding: 0,

  span: {
    display: "inherit",

    "&:first-of-type": {
      position: "relative",
      alignItems: "inherit",
      justifyContent: "inherit",
      width: "$12",
      height: "$12",
      backgroundColor: "$backgroundSecondary",
      borderRadius: "50%",
      fontSize: "$subtitleMobile",
      fontWeight: "$bold",
      lineHeight: "$short",

      "@desktop": {
        width: "$16",
        height: "$16",
        fontSize: "$subtitleDesk",
      },

      "&::before": {
        content: "",
        position: "absolute",
        inset: 0,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: "inherit",
        transitionProperty: "border-color",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-out",
      },
    },

    "&:last-of-type": {
      fontSize: "$auxiliar",
      lineHeight: "$short",
      minHeight: "$8",
      padding: "0 $1",
    },
  },
});

export { Button, Container };
