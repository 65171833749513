import {
  type HttpQueryParams,
  type IHttpResult,
} from "@grupoboticario/vdi-mfe-utils";

export type QueryParams = Record<
  string,
  string | boolean | number | string[] | number[]
>;

interface API {
  getSurvey: ApiMethod;
  postSurveyAnswers: ApiPostMethod;
}

type ApiPostMethod = <ApiResData>(
  body?: Record<string, unknown>,
) => Promise<IHttpResult<ApiResData, ApiResError>>;

type ApiMethod = <ApiResData>(
  params?: ApiParams,
  body?: Record<string, unknown>,
) => Promise<IHttpResult<ApiResData, ApiResError>>;
type ApiParams = HttpQueryParams["params"];

interface ApiResError extends Error {
  path: string;
  statusCode: number;
  timestamp: string;
}

enum MetaState {
  IDLE,
  ERROR,
  LOADING,
  FILLED,
  NOT_FOUND,
  UNAVAILABLE,
}

export { MetaState };
export type { API, ApiParams, ApiResError, ApiPostMethod, ApiMethod };
