import { styled } from "@grupoboticario/flora-react";

const Description = styled("p", {
  color: "$nonInteractiveAuxiliar",
  fontSize: "$bodyLarge",
  fontWeight: "$regular",
  lineHeight: "$base",
  marginBottom: 0,
});

export { Description };
