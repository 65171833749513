import { useMaxWidth } from "@grupoboticario/flora-react";

import { UserMenu } from "./user-menu";
import { MenuDesktop } from "./menu-desktop";
import { MenuMobile } from "./menu-mobile/menu-mobile";
import { NotificationMenu } from "./NotificationMenu";
import { MenuProvider } from "./menu";

import {
  RootContainer,
  NavContainer,
  NavItemsContainer,
  NavUserAndControl,
} from "./root.component.styles";

import { ModalSurvey } from "./modal-survey";
import { ApiProvider } from "./modal-survey/providers/api-provider";
import { makeApiClient } from "./modal-survey/api/api.http";
import { MonitoringClient } from "@grupoboticario/vdi-mfe-utils";

const monitor = MonitoringClient.createGlobalAgent();
const api = makeApiClient(monitor);

export default function Root({ user }) {
  const isMobile = useMaxWidth("519px");

  const verifyCookie = (cookieName: string): boolean => {
    return document.cookie.split(";").some(function (cookie) {
      return cookie.trim().startsWith(`${cookieName}=`);
    });
  };

  return (
    <RootContainer>
      <NavContainer as="nav">
        <MenuProvider>
          <ApiProvider api={api}>
            {!verifyCookie("surveyAnswered") && <ModalSurvey />}
          </ApiProvider>
          <NavItemsContainer>
            {isMobile ? <MenuMobile /> : <MenuDesktop />}
          </NavItemsContainer>
        </MenuProvider>
        <NavUserAndControl>
          <NotificationMenu />
          <UserMenu user={user} />
        </NavUserAndControl>
      </NavContainer>
    </RootContainer>
  );
}
