import {
  HttpClient,
  HttpMethod,
  type HttpConfig,
  type IMonitoringClient,
} from "@grupoboticario/vdi-mfe-utils";

import { authService } from "@vdi/auth-service";
import { API } from "./api.types";

const makeHttpConfig = (monitor: IMonitoringClient): HttpConfig => ({
  baseUrl: process.env.API_CSAT_BASE_URL,
  timeout: Number(process.env.API_CSAT_TIMEOUT),
  async getHeaders() {
    const { token } = await authService.getTokenOrRedirect();
    return {
      authorization: `Bearer ${token}`,
      "content-type": "application/json",
    };
  },
  onError({ error }) {
    const err = error as unknown as { message: string };
    monitor.error(new Error(err.message), err);
  },
});

const makeApiClient = (monitor: IMonitoringClient): API => {
  const http = HttpClient.create(makeHttpConfig(monitor));

  return {
    getSurvey: () => {
      return http.query({
        endpoint: `/survey/general`,
      });
    },
    postSurveyAnswers: (body) => {
      return http.mutate({
        method: HttpMethod.Post,
        endpoint: `/survey/answer`,
        body,
      });
    },
  };
};

export { makeApiClient, makeHttpConfig };
