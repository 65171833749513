import { FloraTypography as Typography } from "@grupoboticario/flora-react";

interface MenuItemLabelProps {
  label: string;
}

export function MenuItemLabel({ label }: MenuItemLabelProps) {
  return (
    <Typography
      fontSize="exceptionsAuxiliar"
      fontWeight="medium"
      css={{ textAlign: "center" }}
    >
      {label}
    </Typography>
  );
}
