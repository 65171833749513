import { Fragment, useState, useEffect } from "react";

import { FloraButton as Button, Modal } from "@grupoboticario/flora-react";

import {
  StyledModalContent,
  StyledModalCloseButton,
  StyledModalHeader,
  Title,
  StyledModalFooter,
  StyledModalBody,
} from "./modal-survey.styles";

import { Rating } from "./rating";
import { Observations } from "./observations";

import { useSurveyData } from "./modal-survey.hooks";
import { SurveyDTO } from "./modal-survey.types";
import { useApi } from "./providers/api-provider";
import { Description } from "./observations/observations.styles";

interface AnswerProps {
  questionOptionId: string;
  surveyQuestionId: string;
  comments: string;
}

const COOKIE_NAME = "surveyAnswered";

const ModalSurvey = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isRated, setIsRated] = useState<boolean>(false);
  const [answer, setAnswer] = useState<AnswerProps>(null);
  const [isSendingAnswers, setIsSendingAnswers] = useState<boolean>(false);
  const [sendingError, setSendingError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const api = useApi();

  const { data } = useSurveyData<SurveyDTO>({
    dataSource: "getSurvey",
  });

  const setCookie = (name, value, days): void => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();

    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  const sendAnswers = async () => {
    setIsSendingAnswers(true);

    const result = await api.postSurveyAnswers({
      data: {
        answers: [answer],
      },
    });

    if (result.ok) {
      setIsSuccess(true);
      setCookie(COOKIE_NAME, "true", 1);
    } else {
      setSendingError(true);
      setIsSendingAnswers(false);
    }
  };

  const setRating = (id) => {
    setAnswer({
      ...answer,
      questionOptionId: id,
    });
    setIsRated(true);
  };

  useEffect(() => {
    if (data && !data?.data.surveyAnswered) {
      setModalOpen(true);
      setAnswer((answer) => ({
        ...answer,
        surveyQuestionId: data?.data.survey.surveyQuestions[0].id,
      }));
    }
  }, [data]);

  return (
    <Modal open={modalOpen}>
      <StyledModalContent>
        <StyledModalCloseButton onClick={closeModal} />
        {!isRated && !isSuccess && (
          <Rating
            onRating={(id) => setRating(id)}
            surveyQuestions={data?.data.survey.surveyQuestions}
          />
        )}

        {isRated && !isSuccess && (
          <Observations
            onCloseButton={closeModal}
            surveyQuestions={data?.data.survey.surveyQuestions}
            isSendingAnswers={isSendingAnswers}
            error={sendingError}
            onSendAnswer={(comment) => {
              setAnswer({ ...answer, comments: comment });
              sendAnswers();
            }}
          />
        )}

        {isSuccess && (
          <Fragment>
            <StyledModalHeader>
              <Title>Sua resposta foi enviada!</Title>
            </StyledModalHeader>
            <StyledModalBody>
              <Description>
                Agradecemos por responder nossa pesquisa e ajudar a melhorar
                cada vez mais a VD Interativa.
              </Description>
            </StyledModalBody>
            <StyledModalFooter>
              <Button onClick={closeModal}>Fechar</Button>
            </StyledModalFooter>
          </Fragment>
        )}
      </StyledModalContent>
    </Modal>
  );
};

export { ModalSurvey };
