import { ReactNode, createContext, useState } from "react";

interface MenuProps {
  children: ReactNode;
}

interface MenuContextData {
  activeMenuItem: (e: string) => void;
  checkUrl: (e: string) => boolean;
}

export const MenuContext = createContext<MenuContextData>(
  {} as MenuContextData,
);

export function MenuProvider({ children }: MenuProps) {
  const [itemUrl, setItemUrl] = useState<string>(window.location.pathname);

  function activeMenuItem(url: string) {
    setItemUrl(url);
  }

  function checkUrl(url: string) {
    if (url === "/") {
      return itemUrl === url;
    }

    return itemUrl.includes(url);
  }

  return (
    <MenuContext.Provider value={{ activeMenuItem, checkUrl }}>
      {children}
    </MenuContext.Provider>
  );
}
