import { Fragment, useState } from "react";

import {
  FloraTextarea as Textarea,
  FloraButton as Button,
} from "@grupoboticario/flora-react";

import {
  StyledModalBody,
  StyledModalHeader,
  StyledModalFooter,
  Title,
} from "../modal-survey.styles";

import { Description } from "./observations.styles";

import { SurveyQuestionProps } from "../modal-survey.types";

interface ObservationsProps {
  onCloseButton?: () => void;
  surveyQuestions: SurveyQuestionProps[];
  onSendAnswer: (comment: string) => void;
  isSendingAnswers: boolean;
  error: boolean;
}

const HELPER_TEXT =
  "Não foi possível enviar sua resposta. Por favor, tente novamente.";

const Observations = ({
  onCloseButton,
  surveyQuestions,
  onSendAnswer,
  isSendingAnswers,
  error,
}: ObservationsProps): JSX.Element => {
  const [comment, setComment] = useState<string>("");

  const renderObservationsTitle = surveyQuestions.map((item) => {
    return (
      <StyledModalHeader key={item.id}>
        <Title>{item.question.commentsTitle}</Title>
        <Description>{item.question.commentsSubtitle}</Description>
      </StyledModalHeader>
    );
  });

  return (
    <Fragment>
      {renderObservationsTitle}

      <StyledModalBody>
        <Textarea
          css={{ height: "152px", resize: "none" }}
          id="observations"
          label="Comentário:"
          placeholder="Digite sua resposta aqui"
          status={error ? "error" : "neutral"}
          helperText={error ? HELPER_TEXT : ""}
          maxLength={1000}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          disabled={isSendingAnswers}
        />
      </StyledModalBody>

      <StyledModalFooter>
        <Button
          isFull
          onClick={() => onSendAnswer(comment)}
          isLoading={isSendingAnswers}
          disabled={isSendingAnswers}
        >
          {error ? "Enviar novamente" : "Enviar resposta"}
        </Button>

        {error && (
          <Button isFull hierarchy="secondary" onClick={onCloseButton}>
            Responder depois
          </Button>
        )}
      </StyledModalFooter>
    </Fragment>
  );
};

export { Observations, ObservationsProps };
