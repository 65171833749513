import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  renderType: "createRoot",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
export { useNotificationListener } from "./NotificationMenu/hooks/useNotificationListener";
export { useReadAllNotificationsFromAGroup } from "./NotificationMenu/hooks/useReadAllNotificationsFromAGroup";

if (module.hot) {
  module.hot.accept();
}
