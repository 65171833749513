import { useContext, useState, Fragment } from "react";
import { navigateToUrl } from "single-spa";

import {
  Flex,
  Box,
  FloraTypography as Typography,
  FloraButton as Button,
} from "@grupoboticario/flora-react";

import {
  ArrowLeftIcon,
  ChevronRightIcon,
} from "@grupoboticario/flora-react-icons";

import {
  SideMenuList,
  SideMenuItem,
} from "@grupoboticario/flora-shared-react-side-menu";

import { MenuContext, menuType, useSendClickMenuEvent } from "../menu";

import { MenuItemSidemenu } from "./menu-item.styles";

export function MenuItem({
  text,
  icon = null,
  options = [],
  url,
  onMenuItemClick = null,
  isActive = false,
}) {
  const [isSubmenuOpen, setIsSubMenuOpen] = useState(false);
  const { activeMenuItem, checkUrl } = useContext(MenuContext);

  const triggerMenuEvent = useSendClickMenuEvent();

  const handleMenuItemClicked = () => {
    // Se o menu não tiver submenus, ativa o item e navega para a URL diretamente
    if (options.length === 0) {
      activeMenuItem(url);
      onMenuItemClick();
      navigateToUrl(url);
    }

    triggerMenuEvent({ url, menu: menuType.MENU });
    setIsSubMenuOpen(!isSubmenuOpen);
  };

  const navigate = (url) => {
    triggerMenuEvent({ url, menu: menuType.SUBMENU });
    activeMenuItem(url);
    onMenuItemClick();
    navigateToUrl(url);
  };

  return (
    <Fragment>
      <Flex
        gap="$4"
        css={{
          color: isActive ? "#626CC3" : "$nonInteractiveAuxiliar",
          padding: "$4",
          borderBottom: "1px solid $nonInteractiveAltAuxiliar",
          cursor: "pointer",
          height: 56,
        }}
        align="center"
        onClick={handleMenuItemClicked}
        role="listitem"
        aria-label={text}
      >
        {Boolean(icon) && icon}
        <Box css={{ flex: 1 }}>
          <Typography fontSize="bodySmallShort" fontWeight="medium" as="span">
            {text}
          </Typography>
        </Box>
        <ChevronRightIcon />
      </Flex>
      {isSubmenuOpen && (
        <Flex
          css={{
            position: "fixed",
            top: 64,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "$backgroundPrimary",
          }}
          direction={"column"}
        >
          <Flex
            align={"center"}
            gap="$2"
            css={{
              height: 56,
              padding: "$4 $4",
              borderBottom: "1px solid $nonInteractiveAltAuxiliar",
            }}
          >
            <Button
              hierarchy="tertiary"
              has="iconOnly"
              icon={
                <ArrowLeftIcon
                  color="$statusAlertActionableActive"
                  size={"$8"}
                />
              }
              aria-label={`Fechar menu de ${text}`}
              onClick={handleMenuItemClicked}
              css={{
                padding: "$0",
              }}
            />
            <Typography
              fontSize="bodyLargeShort"
              fontWeight="medium"
              role="heading"
              as="span"
            >
              {text}
            </Typography>
          </Flex>
          <MenuItemSidemenu>
            <SideMenuList>
              {options?.map((option) => {
                if (option.options) {
                  return (
                    <SideMenuItem key={option.label} label={option.label}>
                      {option.options?.map((opt) => (
                        <SideMenuItem
                          key={opt.label}
                          isActive={checkUrl(opt.url)}
                          label={opt.label}
                          onClick={() => navigate(opt.url)}
                        />
                      ))}
                    </SideMenuItem>
                  );
                }
                return (
                  <SideMenuItem
                    key={option.label}
                    isActive={checkUrl(option.url)}
                    label={option.label}
                    onClick={() => navigate(option.url)}
                  />
                );
              })}
            </SideMenuList>
          </MenuItemSidemenu>
        </Flex>
      )}
    </Fragment>
  );
}
