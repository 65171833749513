import { useContext } from "react";
import { navigateToUrl } from "single-spa";

import {
  SideMenu,
  SideMenuHeader,
  SideMenuItem,
  SideMenuList,
} from "@grupoboticario/flora-shared-react-side-menu";

import { Menu, MenuContext, menuType, useSendClickMenuEvent } from "../menu";
import { VdiLogo } from "../vdi-logo";
import { options } from "../menu-options";
import { menuEnabler } from "@shared/functions/menu-enabler";

export function MenuDesktop() {
  const { activeMenuItem, checkUrl } = useContext(MenuContext);

  const triggerMenuEvent = useSendClickMenuEvent();

  const navigate = (url) => {
    triggerMenuEvent({ url, menu: menuType.SUBMENU });
    activeMenuItem(url);
    navigateToUrl(url);
  };

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          return navigate("/");
        }}
        style={{ cursor: "pointer" }}
      >
        <VdiLogo />
      </a>
      <Menu.Root>
        {options.map((item) => {
          if (!menuEnabler(item.features)) {
            return null;
          }

          return (
            <Menu.Item
              key={item.label}
              isActive={checkUrl(item.url)}
              onClick={() => {
                if (!item?.options) return navigate(item.url);
              }}
            >
              <Menu.Icon icon={item.icon} />
              <Menu.Label label={item.label} />
              {item.options && (
                <Menu.SubItem>
                  <SideMenu
                    className="subMenu"
                    css={{ backgroundColor: "$backgroundPrimary" }}
                  >
                    <SideMenuHeader
                      css={{
                        padding: "$5 $4",
                        lineHeight: "$shorter",
                        fontWeight: "$medium",
                        backgroundColor: "$backgroundPrimary",
                        color: "$nonInteractivePredominant",
                        margin: "0",
                        borderBottom: "1px solid $nonInteractiveOutline",
                      }}
                    >
                      {item.label}
                    </SideMenuHeader>
                    <SideMenuList>
                      {item.options.map((subItem) =>
                        subItem.options ? (
                          <SideMenuItem
                            key={subItem.label}
                            label={subItem.label}
                          >
                            {subItem.options.map((item) => (
                              <SideMenuItem
                                key={item.label}
                                label={item.label}
                                onClick={() => navigate(item.url)}
                                isActive={checkUrl(item.url)}
                              />
                            ))}
                          </SideMenuItem>
                        ) : (
                          <SideMenuItem
                            key={subItem.label}
                            label={subItem.label}
                            onClick={() => navigate(subItem.url)}
                            isActive={checkUrl(subItem.url)}
                          />
                        ),
                      )}
                    </SideMenuList>
                  </SideMenu>
                </Menu.SubItem>
              )}
            </Menu.Item>
          );
        })}
      </Menu.Root>
    </>
  );
}
