import { IHttpResult, QueryParams } from "@grupoboticario/vdi-mfe-utils";
import { API, ApiParams, ApiResError, MetaState } from "./api/api.types";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "./providers/api-provider";

interface UseApiDataInput {
  dataSource: keyof API;
  fetchOnMount?: boolean;
  getApiParams?: (() => Promise<ApiParams>) | (() => ApiParams);
  pollingOnError?: boolean;
  body?: Record<string, unknown>;
}

type UseApiDataOutput<TData> = UseApiDataFilled<TData> | UseApiDataUnfilled;

interface UseApiDataFilled<TData> extends UseApiDataCommon<TData> {
  filled: true;
  data: TData;
}

interface UseApiDataUnfilled extends UseApiDataCommon {
  filled: false;
  data: undefined;
}

interface UseApiDataCommon<TData = undefined> extends AsyncState {
  refetch: () => void;
  getData: (params?: QueryParams) => Promise<IHttpResult<TData, ApiResError>>;
}

interface State<TData> {
  data?: TData;
  status: MetaState;
}

const useSurveyData = <TData>({
  fetchOnMount = true,
  getApiParams,
  dataSource,
  pollingOnError,
}: UseApiDataInput): UseApiDataOutput<TData> => {
  const [stateResult, setStateResult] = useState<State<TData>>({
    status: MetaState.IDLE,
  });

  const state = useAsyncState(stateResult.status);
  const api = useApi();

  const getApiResult = useCallback(
    async (apiParams?: QueryParams) => {
      setStateResult((r) => ({ ...r, status: MetaState.LOADING }));
      const params = await getApiParams?.();
      const result = await api[dataSource]<TData>({ ...params, ...apiParams });

      return result;
    },
    [getApiParams, api, dataSource],
  );

  const getApiData = useCallback(
    async (params?: QueryParams) => {
      const result = await getApiResult(params);

      if (result.ok) {
        setStateResult({ data: result.value, status: MetaState.FILLED });
      } else {
        if (result.code === 404) {
          setStateResult((r) => ({ ...r, status: MetaState.NOT_FOUND }));
        } else {
          setStateResult((r) => ({ ...r, status: MetaState.ERROR }));
        }
      }

      return result;
    },
    [getApiResult, pollingOnError],
  );

  if (fetchOnMount) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      void getApiData();
    }, [getApiData]);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return {
    refetch: getApiData,
    getData: getApiData,
    data: stateResult.data,
    ...state,
  };
};

interface AsyncState {
  filled: boolean;
  error: boolean;
  loading: boolean;
  idle: boolean;
  notFound: boolean;
}

const useAsyncState = (state: MetaState): AsyncState => ({
  error: state === MetaState.ERROR,
  filled: state === MetaState.FILLED,
  loading: state === MetaState.LOADING,
  idle: state === MetaState.IDLE,
  notFound: state === MetaState.NOT_FOUND,
});

export { useAsyncState, useSurveyData };
